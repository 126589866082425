import React, { Component } from 'react';
import './App.css';

export default class Landing extends Component {

    render() {
        return <div>
            <div class="jumbotron jumbotron-fluid">
                <div class="container">
                    <h1 class="display-4">Streamline Your Patient Intake Process</h1>
                    <p class="lead">Welcome to Intake Tree, the SaaS solution designed to help therapist practices simplify their patient intake process and match patients with the right therapist quickly and easily.</p>
                    <a href="#" class="btn btn-primary btn-lg">Get Started</a>
                </div>
            </div>

            <div class="container my-5">
                <h2>Features</h2>
                <hr />
                <div class="row">
                    <div class="col-md-4">
                        <h4>Patient/Therapist Matching</h4>
                        <p>Our platform automates patient intake by assisting intake staff in collecting key information from patients when they call in, such as their presenting issues, insurance, availability, and other important factors. Using this information, Intake Tree automatically narrows down which therapists might be a good match for the patient. </p>
                    </div>
                    <div class="col-md-4">
                        <h4>Streamline Your Workflow</h4>
                        <p>Matching patients with the right therapist can be a complex and time-consuming process, often relying on the expertise and knowledge of your intake staff. Our platform automates this process, reducing errors and inefficiencies associated with manual matching. You can be confident that patients are being matched with the most appropriate therapist for their needs, resulting in improved patient care.</p>
                    </div>
                    <div class="col-md-4">
                        <h4>Fully Customizable</h4>
                        <p>We understand that each practice has unique criteria for matching patients with therapists. That's why Intake Tree is fully customizable, allowing you to tailor the matching process to your practice's specific needs. You can configure the platform to take into account factors like age, gender, presenting issues, therapist specialties, modalities, insurance, and more, ensuring that patients are matched with the most appropriate therapist for their needs.</p>
                    </div>
                </div>
            </div>

            <div>
                <div class="bg-light py-5">
                    <div class="container">
                        <h2>Why Intake Tree?</h2>
                        <hr />
                        <div class="row">
                            <div class="col-md-4">
                                <h3>Improved Accuracy</h3>
                                <ul>
                                    <li>Automated matching reduces the likelihood of errors associated with manual matching</li>
                                    <li>Customizable criteria ensure that patients are matched with the most appropriate therapist for their needs</li>
                                    <li>Consistent matching criteria across your practice ensures that patients are matched based on your best practices</li>
                                </ul>
                            </div>
                            <div class="col-md-4">
                                <h3>Increased Efficiency</h3>
                                <ul>
                                    <li>Automated matching frees up intake staff to focus on other important tasks</li>
                                    <li>Streamlined patient intake process saves time for both staff and patients</li>
                                    <li>Easy-to-use platform reduces the need for extensive training and onboarding</li>
                                </ul>
                            </div>
                            <div class="col-md-4">
                                <h3>Better Patient Care</h3>
                                <ul>
                                    <li>Faster matching means patients can get the care they need more quickly</li>
                                    <li>Improved accuracy ensures that patients are matched with therapists who are best suited to their needs</li>
                                    <li>Increased patient satisfaction and engagement lead to better health outcomes</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="py-5">
                    <div class="container">
                        <h2>Pricing Plans</h2>
                        <hr />
                        <div class="row">
                            <div class="col-md-4">
                                <div class="card mb-4 shadow-sm">
                                    <div class="card-header">
                                        <h4 class="my-0 font-weight-normal">Free</h4>
                                    </div>
                                    <div class="card-body">
                                        <h1 class="card-title pricing-card-title">$0<small class="text-muted">/month</small></h1>
                                        <ul class="list-unstyled mt-3 mb-4">
                                            <li>Basic patient matching</li>
                                            <li>Up to 3 intake staff seats</li>
                                            <li>Up to 10 therapists</li>
                                            <li>Email support only</li>
                                        </ul>
                                        <button type="button" class="btn btn-lg btn-block btn-outline-primary">Sign up for Free</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card mb-4 shadow-sm">
                                    <div class="card-header">
                                        <h4 class="my-0 font-weight-normal">Standard</h4>
                                    </div>
                                    <div class="card-body">
                                        <h1 class="card-title pricing-card-title">$9
                                            <small class="text-muted">/month</small>
                                        </h1>
                                        <ul class="list-unstyled mt-3 mb-4">
                                            <li>Customizable patient matching</li>
                                            <li>Unlimited intake staff seats</li>
                                            <li>Up to 25 therapists</li>
                                            <li>Email and phone support</li>
                                        </ul>
                                        <button type="button" class="btn btn-lg btn-block btn-primary">Get Started</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card mb-4 shadow-sm">
                                    <div class="card-header">
                                        <h4 class="my-0 font-weight-normal">Pro</h4>
                                    </div>
                                    <div class="card-body">
                                        <h1 class="card-title pricing-card-title">$19<small class="text-muted">/month</small></h1>
                                        <ul class="list-unstyled mt-3 mb-4">
                                            <li>Customizable patient matching</li>
                                            <li>Unlimited intake staff seats</li>
                                            <li>Unlimited therapists</li>
                                            <li>Priority support</li>
                                        </ul>
                                        <button type="button" class="btn btn-lg btn-block btn-primary">Contact Us</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="bg-light py-5">
                    <div class="container">
                        <h2 class="text-center mb-5">What Our Customers Are Saying</h2>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card p-4 mb-4">
                                    <div class="card-body">
                                        <p class="card-text">"Using Intake Tree has made our patient intake process so much smoother and faster. We're able to match patients with the right therapist in no time, and our patients appreciate the personalized care they receive."</p>
                                    </div>
                                    <div class="card-footer text-right">
                                        <small class="text-muted">John, Practice Manager</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card p-4 mb-4">
                                    <div class="card-body">
                                        <p class="card-text">"I was skeptical at first, but after using Intake Tree for a few days, I'm a believer. The platform is easy to use and has saved us so much time and headache."</p>
                                    </div>
                                    <div class="card-footer text-right">
                                        <small class="text-muted">Lisa, Intake Coordinator</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div>
                <div class="py-5">
                    <div class="container">
                        <h2>Contact Us</h2>
                        <hr />
                        <div class="row">
                            <div class="col-md-6">
                                <h4>Customer Support</h4>
                                <p>For any questions or concerns, please email us at support@intake-tree.com.</p>
                            </div>
                            <div class="col-md-6">
                                <h4>Get in Touch</h4>
                                <p>For sales inquiries, partnerships, or general feedback, please contact us at info@intake-tree.com.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <footer class="bg-light py-3">
                    <div class="container text-center">
                        <p>&copy; Intake Tree 2023. All rights reserved.</p>
                    </div>
                </footer>
            </div>

        </div>;
    }
}

