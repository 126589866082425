import React, { Component } from 'react';
import GoogleLogin from 'react-google-login';
import './App.css';

export default class Login extends Component {

  responseGoogle(googleUser) {
    if (!googleUser.getAuthResponse) {
      return;
    }
    const idToken = googleUser.getAuthResponse().id_token;
    fetch('/gsuitelogin', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: idToken })
    })
      .then(res => res.json())
      .then(res => {
        if (res.err) {
          this.setState({ errMessage: "Login failed! " + res.err });
        } else {
          this.props.setTeam(res);
        }
      });
  }

  render() {
    const errMessage = this.state && this.state.errMessage;
    return (
      <div>
        <div className="container-fluid">
          <div className="row pl-md-3">
            <div className="col-10 p-md-3">
              <div>
                <h1 className="display-4">Log in</h1>

                <div className="pt-md-3 lead">
                  <p>
                    Please log in with your company GMail account.
                  </p>

                  <GoogleLogin
                    clientId="194655801102-ln06sjv196o4ojb0rl5ohoobdbvvk439.apps.googleusercontent.com"
                    buttonText="Log in with Google"
                    onSuccess={u => this.responseGoogle(u)}
                    onFailure={u => this.responseGoogle(u)}
                    cookiePolicy={'single_host_origin'}
                    theme="dark"
                  />

                  {errMessage && <div className="error">{errMessage}</div>}

                </div>

                <h3 className="pt-md-5">Login code</h3>
                <p>
                  If you have a login code, you can paste it into the box below.
                </p>

                <form className="form-inline" method="POST" action="/login">
                  <input className="form-control mr-3" name="token" />
                  <button className="btn it-btn" type="submit">Log in</button>
                </form>

                <h3 className="pt-md-5">Magic login link</h3>
                <p>
                  If you have a Magic login link that looks like
                  <br />
                  <code>https://{document.location.host}/admin/XXXX</code>
                  <br />
                  or
                  <br />
                  <code>https://{document.location.host}/intake/XXXX</code>
                  <br />
                  just paste it into your browser's address bar to get going!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

