import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { DebounceInput } from 'react-debounce-input';
import cx from 'classnames';

export default class Admin extends Component {

  state = {
    dimensions: null,
    entities: null,
    ui: {}
  }

  componentDidMount() {
    fetch('/api/dimensions')
      .then(res => res.json())
      .then(dimensions => this.setStore('dimensions', dimensions));
    fetch('/api/entities')
      .then(res => res.json())
      .then(entities => this.setStore('entities', entities));
  }

  setStore(key, obj) {
    this.setState({ [key]: obj });
  }

  updateStore(key, obj) {
    this.setState(state => {
      _.forEach(obj, (v, k) => {
        state[key][k] = v;
      });
      return state;
    });
  }

  updateObj(typ, key, body, stateObj) {
    if (stateObj) {
      _.merge(stateObj, body);
      this.setState(this.state);
    }

    const method = key === 'new' ? 'POST' : 'PUT';
    const uri = key === 'new' ? `/api/${typ}` : `/api/${typ}/${key}`;

    fetch(uri, {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    .then(res => res.json())
    .then(objs => {
      this.updateStore(typ, objs);
    });
  }

  deleteObj(typ, key) {
    fetch(`/api/${typ}/${key}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
    .then(res => res.json())
    .then(objs => {
      this.setStore(typ, objs);
    });

  }

  sortedDimensions() {
    return _.sortBy(this.state.dimensions, _.some(this.state.dimensions, dim => dim.order) ? 'order' : '_id');
  }

  updateDimension(key, body, stateObj) {
    this.updateObj('dimensions', key, body, stateObj);
  }

  createDimension(event) {
    event.preventDefault();
    this.updateDimension('new', {});
  }

  deleteDimension(event, dimId) {
    event.preventDefault();
    if (_.size(this.state.dimensions[dimId].attributes)) {
      alert("Delete all attributes before deleting this dimension!");
      return;
    }
    this.deleteObj('dimensions', dimId)
  }

  dimensionNameChange(event, key) {
    this.updateDimension(key, { name: event.target.value }, this.state.dimensions[key]);
  }

  dimensionMultiChange(event, key) {
    const multi = event.target.value !== 'Single';
    const any = event.target.value === 'OR'
    this.updateDimension(key, { multi, any });
  }

  sortChange(event, key) {
    const sortBy = event.target.value === 'Alphabetical' ? 'name' : '_id';
    this.updateDimension(key, { sortBy });
  }

  toggleDimEdit(evt, dimId) {
    this.setState(state => {
      state.ui.editDimId = dimId === state.ui.editDimId ? null : dimId;
      return state;
    });
  }

  updateAttribute(dimId, attrId, body, stateObj) {
    if (stateObj) {
      _.merge(stateObj, body);
      this.setState(this.state);
    }

    const method = attrId === 'new' ? 'POST' : 'PUT';
    const baseUri = `/api/dimensions/${dimId}/attributes`;
    const uri = attrId === 'new' ? baseUri : `${baseUri}/${attrId}`;

    fetch(uri, {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    .then(res => res.json())
    .then(dimension => {
      this.updateStore('dimensions', dimension);
    });
  }

  createAttribute(event, dimId) {
    event.preventDefault();
    this.focusDimId = dimId;
    this.updateAttribute(dimId, 'new', { name: ''});
  }

  deleteAttribute(event, dimId, attrId) {
    event.preventDefault();
    if (_.some(this.state.entities, ent => _.some(ent.attributes || [], id => id === attrId))) {
      alert("Cannot delete this attribute because some therapists have it toggled!");
      return;
    }

    fetch(`/api/dimensions/${dimId}/attributes/${attrId}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
    .then(res => res.json())
    .then(dimension => {
      this.updateStore('dimensions', dimension);
    });
  }

  attrNameChange(event, dimId, attrId) {
    this.updateAttribute(dimId, attrId, { name: event.target.value}, this.state.dimensions[dimId].attributes[attrId]);
  }

  updateEntity(key, body, stateObj) {
    this.updateObj('entities', key, body, stateObj);
  }

  createEntity(event) {
    event.preventDefault();
    this.updateEntity('new', { available: true, order: _.size(this.state.entities) });
  }

  deleteEntity(event, entId) {
    event.preventDefault();
    if (window.confirm('Delete this therapist?')) {
      this.deleteObj('entities', entId);
    }
  }

  entityNameChange(event, key) {
    this.updateEntity(key, { name: event.target.value }, this.state.entities[key]);
  }

  entityNotesChange(event, key) {
    this.updateEntity(key, { notes: event.target.value }, this.state.entities[key]);
  }

  entityAvailableChange(event, key, newValue) {
    this.updateEntity(key, { available: newValue });
  }

  entityMoveUp(event, key, idx) {
    if (!_.every(this.state.entities, ent => ent.order !== undefined)) {
      _.forEach(_.sortBy(this.state.entities, '_id'), (ent, idx) => {
        ent.order = idx;
      });
    }

    const sortedEntities = _.sortBy(this.state.entities, 'order');
    sortedEntities[idx].order = idx - 1;
    sortedEntities[idx - 1].order = idx;

    _.forEach(sortedEntities, ent => {
      this.updateEntity(ent._id, { order: ent.order });
    })
  }

  toggleAttribute(event, entId, attrId, newValue) {
    const method = newValue ? 'POST' : 'DELETE';
    const uri = `/api/entities/${entId}/attributes/${attrId}`;

    fetch(uri, {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then(res => res.json())
    .then(entity => {
      this.updateStore('entities', entity);
    });
  }

  selectDimension(event, dimId) {
    this.setState({ ui: { dim: dimId }});
  }

  lockTeam(event, locked) {
    fetch('/api/team', {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({'locked': locked})
    })
    .then(res => res.json())
    .then(team => {
      this.props.setTeam(team);
    });
  }

  render() {
    if (!this.props.team.isAdmin) return null;
    if (this.state.dimensions === null) return null;
    if (this.state.entities === null) return null;

    const generalDim = { name: 'General', _id: 'general' };
    const selectedDim = this.state.ui.dim
      ? _.get(this.state.dimensions, this.state.ui.dim, generalDim)
      : generalDim;
    const editDimId = this.state.ui.editDimId;
    const entitySort = _.every(this.state.entities, ent => ent.order !== undefined) ? 'order' : '_id';

    return (
      <div className="container-fluid">
      	<div className="row pl-md-3">
          <div className="col-12 p-md-3">

            <div className="lock">
              { this.props.team.locked
                ? <div>
                    <img className="locked"
                      alt="locked padlock"
                      src={`${process.env.PUBLIC_URL}/img/padlock_closed.png`}
                      onClick={evt => this.lockTeam(evt, false)}
                    />
                    <div className="row">
                      click the lock to<br/>
                      make changes
                    </div>
                  </div>
                : <div>
                  <img className="unlocked"
                    alt="unlocked padlock"
                    src={`${process.env.PUBLIC_URL}/img/padlock_open.png`}
                    onClick={evt => this.lockTeam(evt, true)}
                  />
                  <div className="row">
                    click the lock to<br/>
                    prevent changes
                  </div>
                  </div>
              }
            </div>

            <h2 className="pt-md-3">Dimensions</h2>

            <form>
              {
                _.isEmpty(this.state.dimensions)
                ? <div className="row pl-md-3 pb-md-3">
                  No dimensions yet! Dimensions are groups of attributes for intake patients that help you narrow down the
                  list of suitable therapists.<br/>
                  Examples: Age, Presenting Issue, Insurance, Location, Availability
                  </div>
                : _.map(this.sortedDimensions(), (dim) => {
                  const attributes = _.sortBy(dim.attributes || [], '_id');
                  const multiSelectValue = dim.multi ? (dim.any ? "OR" : "AND") : "Single";
                  const sortValue = dim.sortBy === 'name' ? 'Alphabetical' : 'As is';
                  const attrList = attributes.length ? _.join(_.map(attributes, 'name'), ', ') : "(none)";
                  return (
                    <div key={dim._id} className="pl-1">
                      <div className="form-row p-md-1">
                        <div className="form-inline">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text">Name</div>
                            </div>
                            <DebounceInput
                              className="form-control font-weight-bold"
                              value={dim.name}
                              placeholder="(none)"
                              debounceTimeout={250}
                              onChange={evt => this.dimensionNameChange(evt, dim._id)}
                            />
                          </div>
                        </div>
                      </div>
                      { editDimId !== dim._id ?
                        <div>
                          <div className="form-row p-md-1">
                            <div className="form-inline">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">Attributes</div>
                                </div>
                                <input type="text" size={50} className="form-control it-disabled-input" disabled={true} value={attrList}/>
                                <div className="input-group-append">
                                  { !this.props.team.locked && <button className="btn it-btn-outline" onClick={evt => this.toggleDimEdit(evt, dim._id)}>Edit</button> }
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-row p-md-1">
                            <div className="form-inline pr-2">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">Select mode</div>
                                </div>
                                <select className="custom-select" value={multiSelectValue} onChange={evt => this.dimensionMultiChange(evt, dim._id)}>
                                  <option>Single</option>
                                  <option>OR</option>
                                  <option>AND</option>
                                </select>
                              </div>
                            </div>
                            <div className="form-inline pr-2">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">Sort</div>
                                </div>
                                <select className="custom-select" value={sortValue} onChange={evt => this.sortChange(evt, dim._id)}>
                                  <option>As is</option>
                                  <option>Alphabetical</option>
                                </select>
                              </div>
                            </div>
                            <div className="form-inline">
                            { !this.props.team.locked &&
                              <button className="btn it-btn-outline" onClick={evt => this.deleteDimension(evt, dim._id)}>Delete</button>
                            }
                            </div>
                          </div>
                        </div>
                        :
                        <div>
                          {
                            _.map(attributes, (attr, i) => {
                              return (
                                <div className="form-row col-3" key={attr._id}>
                                  <div className="input-group pb-1">
                                    <DebounceInput
                                      className="form-control"
                                      value={attr.name}
                                      debounceTimeout={250}
                                      onChange={evt => this.attrNameChange(evt, dim._id, attr._id)}
                                      autoFocus={i === _.size(attributes) - 1 && dim._id === this.focusDimId}
                                    />
                                    <div className="input-group-append">
                                    { !this.props.team.locked &&
                                      <button className="btn it-btn-outline" onClick={evt => this.deleteAttribute(evt, dim._id, attr._id)}>&times;</button>
                                    }
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          }
                          <div className="form-row col-3">
                            <div className="input-group">
                              { !this.props.team.locked &&
                                <button
                                  className="btn it-btn-outline form-control"
                                  onClick={evt => this.createAttribute(evt, dim._id)}
                                >Add Attribute</button>
                              }
                              <button
                                className="btn it-btn-outline form-control"
                                onClick={evt => this.toggleDimEdit(evt, dim._id)}
                              >Done</button>
                            </div>
                          </div>
                        </div>
                      }
                      <hr/>
                    </div>
                  );
                })
              }
              <div className="form-row p-md-1">
                <div className="col-2">
                { !this.props.team.locked &&
                  <button
                    className="btn it-btn"
                    onClick={evt => this.createDimension(evt)}
                  >Add Dimension</button>
                }
                </div>
              </div>
            </form>

            <h2 className="pt-md-5">Therapists</h2>
            {
              _.isEmpty(this.state.entities)
              ? <div>
                  <div className="row pl-md-3 pb-md-3">
                    No therapists yet! Add one here.
                  </div>
                  { !this.props.team.locked &&
                    <button
                      className="btn it-btn mt-md-1"
                      onClick={evt => this.createEntity(evt)}
                    >Add Therapist</button>
                  }
                </div>
              :
            <table className="table table-borderless table-sm it-table it-table-fixed">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col" colSpan={100}>
                    <div className="btn-group">
                      {
                        _.map(_.concat([generalDim], this.sortedDimensions()), (dim) => {
                          const selected = dim._id === selectedDim._id;
                          const classes = cx("btn", {
                            "it-btn": selected,
                            "it-btn-outline": !selected,
                          });
                          return (
                            <button
                              key={`dim-btn-${dim._id}`}
                              className={classes}
                              onClick={evt => this.selectDimension(evt, dim._id)}
                            >{dim.name}</button>);
                        })
                      }
                    </div>
                  </th>
                </tr>
                <tr>
                  <th scope="col" width={206} className="it-th it-th-1">Therapist</th>
                  {
                    selectedDim._id === 'general'
                    ? <Fragment>
                        <th scope="col" className="it-th-2 rotate"><div><span>Taking new patients</span></div></th>
                        <th
                          scope="col"
                          colSpan={10}
                          key="th2-general"
                          className="it-th"
                        >Notes</th>
                      </Fragment>
                    : _.map(_.sortBy(_.get(selectedDim, 'attributes', []), selectedDim.sortBy || '_id'), (attr) => {
                      return (
                        <th scope="col"
                          key={`th2-${selectedDim._id}-${attr._id}`}
                          width={46}
                          className="rotate it-th-2"
                        ><div><span>{attr.name}</span></div></th>
                      );
                    })
                  }
                  <th width="*"/>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(_.sortBy(this.state.entities, entitySort), (ent, idx) => {
                    const classes = cx("it-td", {"attr-on": ent.available});
                    return (
                      <tr key={ent._id}>
                        <td width={205} className="it-td-1">
                          <DebounceInput
                            className="form-control it-input"
                            value={ent.name}
                            debounceTimeout={250}
                            onChange={evt => this.entityNameChange(evt, ent._id)}
                          />
                        </td>
                        {
                          selectedDim._id === 'general'
                          ? <Fragment>
                              <td
                                width={46}
                                className={classes}
                                onClick={evt => this.entityAvailableChange(evt, ent._id, !ent.available)}
                              ></td>
                              <td
                                colSpan={10}
                                width={600}
                                key={`td-${ent._id}-general`}
                              >
                                <DebounceInput
                                  className="form-control it-input-wide"
                                  value={ent.notes}
                                  debounceTimeout={250}
                                  onChange={evt => this.entityNotesChange(evt, ent._id)}
                                />
                              </td>
                              <td>
                                { idx > 0 && !this.props.team.locked &&
                                  <button
                                    className="btn it-btn-outline"
                                    onClick={evt => this.entityMoveUp(evt, ent._id, idx)}
                                    >Up</button>
                                }
                              </td>
                              <td>
                              { !this.props.team.locked &&
                                <button
                                  className="btn it-btn-outline"
                                  onClick={evt => this.deleteEntity(evt, ent._id)}
                                >&times;</button>
                              }
                              </td>
                            </Fragment>
                          : _.map(_.sortBy(_.get(selectedDim, 'attributes', []), selectedDim.sortBy || '_id'), (attr) => {
                            const attrOn = _.some(ent.attributes || [], id => id === attr._id);
                            const classes = cx("it-td", {"attr-on": attrOn});
                            return (
                              <td
                                width={46}
                                key={`td-${ent._id}-${attr._id}`}
                                className={classes}
                                onClick={evt => this.toggleAttribute(evt, ent._id, attr._id, !attrOn)}
                              ></td>
                            );
                          })
                        }
                        <td width="*"/>
                      </tr>
                    );
                  })
                }
                <tr>
                  <td>
                  { !this.props.team.locked &&
                    <button
                      className="btn it-btn mt-md-1"
                      onClick={evt => this.createEntity(evt)}
                    >Add Therapist</button>
                  }
                  </td>
                </tr>
              </tbody>
            </table>
          }
          </div>
        </div>
      </div>
    );
  }
}
