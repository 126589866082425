import _ from 'lodash';
import React, { Component } from 'react';
import cx from 'classnames';

export default class Intake extends Component {

  state = {
    dimensions: null,
    entities: null,
    ui: {}
  }

  componentDidMount() {
    fetch('/api/dimensions')
      .then(res => res.json())
      .then(dimensions => this.setStore('dimensions', dimensions));
    fetch('/api/entities?available=true')
      .then(res => res.json())
      .then(entities => this.setStore('entities', entities));
  }

  setStore(key, obj) {
    this.setState({ [key]: obj });
  }

  toggleAttribute(evt, dim, attrId, newValue) {
    this.setState(state => {
      if (dim.multi) {
        const fn = newValue ? _.union : _.difference;
        state.ui[dim._id] = fn(state.ui[dim._id] || [], [attrId]);
      } else {
        state.ui[dim._id] = newValue ? [attrId] : [];
      }

      this.updateEntitesFromState(state);
      return state;
    });
  }

  clearAttributes(dim) {
    this.setState(state => {
      if (dim) {
        state.ui[dim._id] = {};
      } else {
        state.ui = {};
      }

      this.updateEntitesFromState(state);
      return state;
    });
  }

  sortedDimensions() {
    return _.sortBy(this.state.dimensions, _.some(this.state.dimensions, dim => dim.order) ? 'order' : '_id');
  }

  updateEntitesFromState(state) {
    const params = _.map(this.state.ui, (attrIds, dimId) => {
      const dim = state.dimensions[dimId];
      const sep = dim.any ? '|' : ',';
      return `attrs[${dimId}]=${_.join(attrIds, sep)}`;
    });
    const query = _.join(params, '&');
    fetch(`/api/entities?available=true&${query}`)
      .then(res => res.json())
      .then(entities => this.setStore('entities', entities));
  }

  render() {
    if (this.state.dimensions === null) return null;
    if (this.state.entities === null) return null;

    const entitySort = _.every(this.state.entities, ent => ent.order !== undefined) ? 'order' : '_id';

    return (
      <div className="container-fluid">
        <div className="row pt-md-3 pl-md-3">
          <div className="col-6 p-md-3">
            {
              _.map(this.sortedDimensions(), (dim) => {
                return (
                  <div key={dim._id}>
                    <h3 className="it-hover mb-md-3">
                      {dim.name}
                    </h3>
                    <table className="table table-borderless it-table">
                      <tbody>
                        {
                          _.map(_.chunk(_.sortBy(dim.attributes || [], dim.sortBy || '_id'), 3), (attrs, idx) => {
                            return (
                              <tr key={`attrs-group-${idx}`}>
                                {
                                  _.map(attrs, attr => {
                                    const attrOn = _.some(this.state.ui[dim._id] || [], id => id === attr._id);
                                    const classes = cx("it-td", { "attr-on": attrOn });
                                    return <td
                                      key={attr._id}
                                      onClick={evt => this.toggleAttribute(evt, dim, attr._id, !attrOn)}
                                      className={classes}
                                    >{attr.name}</td>
                                  })
                                }
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                );
              })
            }
            <button className="btn it-btn mt-md-4"
              onClick={evt => this.clearAttributes()}
            >Clear All</button>
          </div>

          <div className="col-3 p-md-3">
            <h3>Therapists</h3>
            <ul className="list-group list-group-flush">
              {
                _.size(this.state.entities) > 0 ?
                  _.map(_.sortBy(this.state.entities, entitySort), (ent) => {
                    return (
                      <li key={ent._id} className="list-group-item">
                        <div><strong>{ent.name}</strong></div>
                        {ent.notes && <small><ul className="pl-4">
                          {_.split(ent.notes, ';').map(note => <li>{note}</li>)}
                        </ul></small>}
                      </li>
                    );
                  })
                  : <li className="list-group-item">(No matches)</li>
              }
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
