import React, { Component } from 'react';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Admin from './Admin';
import Intake from './Intake';
import Home from './Home';
import Login from './Login';
import Landing from './Landing';
import './App.css';

export default class App extends Component {

  state = {
    team: {},
    init: false
  };

  componentDidMount() {
    fetch('/api/team')
      .then(res => res.json())
      .then(team => this.setState({ team, init: true }));
  }

  setTeam(team) {
    this.setState({ team, init: true });
  }

  render() {
    if (!this.state.init) return null;

    if (!this.state.team || !this.state.team._id) {
      // Logged out
      return <Router>
        <div>
          <nav className="navbar navbar-expand-lg navbar-dark it-navbar">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <NavLink to="/" className="navbar-brand">Intake Tree</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/features" className="nav-link" activeClassName="active">Features</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/demo" className="nav-link" activeClassName="active">Request a Demo</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/pricing" className="nav-link" activeClassName="active">Pricing</NavLink>
              </li>
            </ul>

            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink to="/signup" className="nav-link" activeClassName="active">Sign up</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/login" className="nav-link" activeClassName="active">Log in</NavLink>
              </li>
            </ul>
          </nav>

          <Route
            path="/"
            exact={true}
            render={(props) =>
              <Landing {...this.props} />
            } />
          <Route
            path="/login"
            render={(props) =>
              <Login {...props}
                setTeam={t => this.setTeam(t)}
              />}
          />
        </div>
      </Router>;
    }

    return (
      <Router>
        <div>
          <nav className="navbar navbar-expand-lg navbar-dark it-navbar">
            <NavLink to="/">
              <span className="navbar-brand">{this.state.team.name}</span>
            </NavLink>
            <ul className="navbar-nav mr-auto">
              {this.state.team.isAdmin &&
                <li className="nav-item">
                  <NavLink to="/admin" className="nav-link" activeClassName="active">Admin</NavLink>
                </li>
              }
              <li className="nav-item">
                <NavLink to="/intake" className="nav-link" activeClassName="active">Intake</NavLink>
              </li>
            </ul>
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink to="/login" className="nav-link" onClickCapture={() => {
                  fetch('/api/signout', { method: 'POST' })
                    .then(res => res.json())
                    .then(team => this.setState({ team: null, init: true }))
                }}>Log out</NavLink>
              </li>
            </ul>
          </nav>

          <Route
            path="/"
            exact={true}
            render={(props) =>
              <Home {...props}
                team={this.state.team}
              />
            } />

          <Route
            path="/admin"
            render={(props) =>
              <Admin {...props}
                team={this.state.team}
                setTeam={t => this.setTeam(t)}
              />
            } />

          <Route
            path="/intake"
            render={(props) =>
              <Intake {...props}
                team={this.state.team}
              />}
          />

        </div>
      </Router>
    );
  }
}
