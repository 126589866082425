import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import './App.css';

export default class Home extends Component {
  select(evt) {
    if (document.selection) {
      const range = document.body.createTextRange();
      range.moveToElementText(evt.target);
      range.select();
    } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(evt.target);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row pl-md-3">
          <div className="col-10 p-md-3">
            <div>
              <h1 className="display-4">Welcome {this.props.team.name}{this.props.team.isAdmin ? " Admin!" : "!"}</h1>
              {
                this.props.team.isAdmin ?
                  <div className="pt-md-3 lead">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="display-4">Admins</h3>
                        <p>
                          Get started setting up your intake columns and values.
                        </p>
                        <p>
                          <NavLink to="/admin" className="btn btn-lg it-btn">Set up</NavLink>
                        </p>
                        <p>
                          Share login code <code
                            className="it-selectable"
                            onClick={evt => this.select(evt)}
                            >{this.props.team.adminToken}</code> with other admins, or have them use this Magic login link:<br/>
                          <code
                            className="it-selectable"
                            onClick={evt => this.select(evt)}
                            >https://{document.location.host}/admin/{this.props.team.adminToken}</code>
                        </p>
                      </div>
                    </div>
                    <div className="card mt-md-3">
                      <div className="card-body">
                        <h3 className="display-4">Intake staff</h3>
                        <p>
                          Preview the experience you've created for your intake staff.
                        </p>
                        <p>
                          <NavLink to="/intake" className="btn btn-lg it-btn">Preview</NavLink>
                        </p>
                        <p>
                          Share login code <code
                            className="it-selectable"
                            onClick={evt => this.select(evt)}
                            >{this.props.team.shareToken}</code> with intake staff, or have them use this Magic login link:<br/>
                          <code
                            className="it-selectable"
                            onClick={evt => this.select(evt)}
                            >https://{document.location.host}/intake/{this.props.team.shareToken}</code>
                        </p>
                      </div>
                    </div>
                  </div>
                :
                  <div className="pt-md-3 lead">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="display-4">Intake staff</h3>
                        <p>
                          Get started with the intake tree!
                        </p>
                        <p>
                          <NavLink to="/intake" className="btn btn-lg it-btn">Get started</NavLink>
                        </p>
                        <p>
                          Share login code <code
                            className="it-selectable"
                            onClick={evt => this.select(evt)}
                            >{this.props.team.shareToken}</code> with other intake staff, or have them use this Magic login link:<br/>
                          <code
                            className="it-selectable"
                            onClick={evt => this.select(evt)}
                            >https://{document.location.host}/intake/{this.props.team.shareToken}</code>
                        </p>
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

